.mat-mdc-button-base {
  
  .mat-icon{
    line-height: 24px;
  }
  // filled button
  &.mat-mdc-unelevated-button {
    &:disabled {
      background: var(--mat-accent-100) !important;
      .mdc-button__label {
        color: white !important;
      }
    }
  }

  // outline button (inverted)
  &.mat-mdc-outlined-button {
    &.mat-accent {
      border-color: var(--mat-accent) !important;
      border-width: 1px;
      border-color: var(--mat-accent-200) !important;
      &:disabled {
        color: var(--mat-accent-200) !important;
        .mdc-button__label {
          background-color: white !important;
        }
      }
    }
  }

  // raised button
  &.mat-mdc-raised-button {
    &.mat-accent {
      &:disabled {
        background-color: var(--mat-accent-200) !important;
        .mdc-button__label {
          color: white !important;
        }
      }
    }
  }

  // spinner button
  &.mat-mdc-button__spinner {
    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }

    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid white;
      border-top-color: var(--mat-accent-200) !important;
      animation: spinner 0.8s linear infinite;
    }
  }

  // icon button only
  &.mat-mdc-icon-button {

    --mdc-icon-button-state-layer-size: 36px;

    padding: 0 !important;
    min-width: 0 !important;
    width: 40px !important;
    height: 40px !important;
    flex-shrink: 0 !important;
    line-height: 40px !important;
    border-radius: 50% !important;

    &:disabled {
      background-color: transparent !important;
      .mat-icon {
        color: rgba(0, 0, 0, 0.26) !important;
      }
    }
  }

  &.mat-btn--with-icon {
    @extend %button-icon-with-text;
  }

  // text-only button
  &.mat-button--text-only {
    background-color: transparent !important;
    .mat-mdc-button-persistent-ripple {
      display: none;
    }
    &:disabled {
      .mdc-button__label {
        color: var(--mat-accent-100) !important;
      }
    }
  }

  &.btn-menu-radius {
    margin-left: 10px;
    border: solid 1px var(--mat-accent) !important;
    border-radius: 3px;
  }

  // mat-menu
  &.btn-menu {
    display: inline-flex;
  }
}