@use '@angular/material' as mat;
@include mat.core();

.mat-mdc-paginator-navigation-previous .mat-mdc-paginator-icon {
  width: 25px !important;
  height: 25px !important;
  background-color: #0078B9 !important;
  border-radius: 5px !important;
  color: white !important;
  margin: 10px !important;
}

.mat-mdc-paginator-navigation-next .mat-mdc-paginator-icon {
  width: 25px !important;
  height: 25px !important;
  background-color: #0078B9 !important;
  border-radius: 5px !important;
  color: white !important;
  margin: 10px !important;
}

.mat-mdc-paginator-navigation-previous .mat-mdc-paginator-icon,
.mat-mdc-paginator-navigation-next .mat-mdc-paginator-icon {
  fill: white !important;
  transform: translateY(-10px) translateX(-10px) !important;
}

::ng-deep .mat-mdc-paginator-navigation-next .mat-mdc-paginator-icon {
  fill: white !important;
  transform: translateY(-8px) !important;
}
.mat-mdc-paginator-navigation-previous:disabled,
.mat-mdc-paginator-navigation-next:disabled {
  background-color: #0078B9 !important;
  opacity: 0.5 !important; /* Reduce opacity to indicate disabled state */
  pointer-events: none; /* Ensure it is not clickable */
}
