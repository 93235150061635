@import 'colors';

%flex-layout {
  display: flex !important;
  flex-direction: column !important;
}

%flex-layout-fill {
  display: flex !important;
  flex-direction: column !important;
  flex: 1 !important;
}

%flex-row-layout-fill {
  display: flex !important;
  flex: 1 !important;
}

%flex-center-all {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

%flex-row-center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

%absolute-layout {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

// Scroll-bar

%hide-vertical-scrollbar {
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

%custom-firefox-scrollbar {
  scrollbar-color: #b0b0b0 $scroll-bar-track; /* thumb and track color */
  scrollbar-width: thin;
}

// Buttons
%button-icon-with-text {
  display: flex !important;
  padding: 0 4px;
  .mat-button-wrapper {
    display: flex !important;

    span,
    .mat-icon {
      flex: 1 !important;
    }
    span {
      margin-left: 5px;
    }
  }
}
