.mat-mdc-snack-bar-container {
  // width: 250px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;

  &.error {
    background: var(--mat-warn) !important;
    color: white !important;

    .mat-simple-snackbar-action button {
      color: white !important;
    }
  }
}
