.mat-checkbox .mat-checkbox-frame {
  border: solid 1px var(--mat-accent);
  border-radius: 3px;
}
.mat-pseudo-checkbox-checked::after {
  top: 3.4px !important;
  left: 2px !important;
}
.mat-pseudo-checkbox {
  border: solid 1px var(--mat-accent) !important;
  border-radius: 3px !important;
}

//For large checkbox
.mat-checkbox--large {
  &.mat-checkbox .mat-checkbox-frame {
    height: 22px;
    width: 22px;
    margin-top: -3px;
    margin-left: -3px;
    border: solid 1px var(--mat-accent-500) !important;
    background-color: var(--mat-primary-50) !important;
    border-radius: 3px;
  }

  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: var(--mat-accent-500) !important;
    height: 22px;
    width: 22px;
    margin-top: -2px;
    margin-left: -2px;
  }
}
