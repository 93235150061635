@import 'src/scss/base/placeholders';

.mat-accordion {
  &.survey-accordion {
    .mat-expansion-panel {
      margin-bottom: 10px !important;
      box-shadow: none !important;

      .mat-expansion-panel-header {
        border-radius: 5px !important;
        background-color: var(--mat-accent) !important;
        height: 60px !important;

        &.mat-expanded {
          height: 60px !important;
        }

        .mat-expansion-indicator::after {
          color: white !important;
        }

        .mat-expansion-panel-header-title {
          align-items: center !important;
          margin: 0 !important;
          color: white !important;
          @extend .text-semibold;
          @extend .mat-subheading-2 !optional;
        }
      }

      .mat-expansion-panel-body {
        padding: 0 !important;
      }

      &.mat-expansion-panel-spacing {
        margin: 0 !important;
      }
    }
  }

  // checkbox multi level accordion
  &.multicheckbox-mat-accordion {
    .mat-expansion-panel {
      box-shadow: none !important;
      margin-bottom: 10px;

      .mat-expansion-panel-header {
        .mat-expansion-indicator::after {
          color: var(--mat-accent) !important;
        }
        .mat-expansion-panel-header-title {
          align-items: center !important;
          margin: 0 !important;
          color: black !important;
          @extend .text-semibold;
          @extend .mat-subheading-2 !optional;
        }
      }
      .mat-expansion-indicator::after {
        color: black !important;
        height: 15px;
        width: 15px;
        &.mat-expansion-panel-spacing {
          margin: 0 !important;
        }
      }
    }
  }

  // tree accordion
  &.tree-mat-accordion {
    .mat-expansion-panel {
      background-color: transparent !important;
      box-shadow: none !important;
      //margin: 0!important;

      .mat-expansion-panel-body {
        @extend %custom-firefox-scrollbar;
        padding: 0 24px !important;
        max-height: 400px !important;
        overflow: auto;
      }

      .mat-expansion-panel-header {
        &.mat-expanded {
          height: 42px !important;
        }
        .mat-content {
          margin-right: 25px !important;
        }
        .mat-expansion-indicator::after {
          color: var(--mat-accent) !important;
        }
        .mat-expansion-panel-header-title {
          align-items: center !important;
          margin: 0 !important;
          color: black !important;
          @extend .text-semibold;
          @extend .mat-subheading-2 !optional;
        }
      }

      .mat-expansion-indicator::after {
        color: black !important;
        height: 15px;
        width: 15px;
        &.mat-expansion-panel-spacing {
          margin: 0 !important;
        }
      }
    }

    .tree-mat-accordion__item {
      display: block !important;
      margin: 20px 0;
      color: $dark-grey !important;
      cursor: pointer;

      &--active {
        color: var(--mat-accent) !important;
      }
    }
  }
}
