@import '/src/scss/base/colors';

/* slide toggle global en bleu  */
.mat-mdc-slide-toggle {
  .mdc-form-field {
    align-items: center;
    .mdc-switch {
      width: 49px;
      .mdc-switch__track {
        height: 26px;
        border-radius: 20px;
        &::before {
          background-color: $side-toggle-background !important;
        }
        &::after {
          background-color: var(--mat-accent) !important;
        }
      }
      .mdc-switch__handle-track {
        transition: all 80ms linear;
        transform: translateX(3px);
        .mdc-switch__handle {
          .mdc-switch__shadow {
            background: $white;
          }
          .mdc-switch__icons {
            display: none;
          }
        }
      }

      &.mdc-switch--disabled {
        .mdc-switch__track {
          &::before {
            background: $disabled;
          }
          &::after {
            background: $disabled;
          }
        }
      }
    }
  }
  &.mat-mdc-slide-toggle-checked {
    .mdc-switch__handle-track {
      transform: translateX(26px) !important;
    }
    .mdc-label {
      color: var(--mat-accent) !important;
    }
  }
}
