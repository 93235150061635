@use '@angular/material' as mat;
@include mat.core();

//========== TYPO =============
// 01_PALETTE
// 02_CUSTOM_COLORS
// 03_BACKGROUND_COLORS
// 04_TEXT_COLORS
//=============================

// 01_PALETTE
$primary-base: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$accent-base: (
  30: #e6f0f6,
  50: #e0eff7,
  100: #b3d7ea,
  200: #80bcdc,
  300: #4da1ce,
  400: #268cc4,
  500: #0078b9,
  600: #0070b2,
  700: #0065aa,
  800: #005ba2,
  900: #004893,
  A100: #bed9ff,
  A200: #8bbcff,
  A400: #589eff,
  A700: #3f8fff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$warn-base: (
  50: #f4e2e8,
  100: #e3b6c6,
  200: #d185a1,
  300: #be547b,
  400: #b02f5e,
  500: #a20a42,
  600: #9a093c,
  700: #900733,
  800: #86052b,
  900: #75031d,
  A100: #ffa3b3,
  A200: #ff7088,
  A400: #ff3d5e,
  A700: #ff2448,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$primary-palette: mat.define-palette($primary-base);
$accent-palette: mat.define-palette($accent-base, 500, 300);
$warn-palette: mat.define-palette($warn-base);

// 02_CUSTOM_COLORS
$success_color: #00b08b;
$primary-darker-color: #f9f9f9;
$accent-transparent-color: #0078b924;

$green-header-background: #3fc4a7;
$text-grey: #7fbbdc;
$side-toggle-background: #0000002b;
$border-grey: #0000001a;
$dark-grey: #555555;
$scroll-bar-track: #eeeeee;
$background-grey: #b2b6be1a;
$card-role-background: #b2b6be26;
$disabled: #b2b6be33;
$background-gray: #f6f6f6;
$background-light-blue: #e8f4f8;
$light-green: #8dc63f;
$background-light-green: #e8f4d8;
$light-red: #dc533a;
$background-light-red: #f8dcd7;
$AAscroll-bar-track: #f6f7f8;
$background-card: #f7f7f7;
$accent-border-color: #7fbbdc;
$card-redaction-eligibility-background: #e16b56;
$table-card-row-border: rgba(0, 120, 185, 0.25);
$card-rights-border: #0078b9;
$card-border: #7784D6;
$card-background-color: #ebf7fb;
$table-card-name-color: #202020;
$table-icon-background: #7FBBDC;
$cancel-btn-background: #b2b6be;
$card-note-background: #0078b919;
$disabled-checkbox: #b0b0b0;
$filter-card-background: #f6f7f8;
$sub-features-accent: #1D2B85;

// 03_BACKGROUND_COLORS
.bg-primary {
  background-color: mat.get-color-from-palette($primary-palette) !important;
}

.bg-accent {
  background-color: mat.get-color-from-palette($accent-palette) !important;
}

.bg-accent-light {
  background-color: mat.get-color-from-palette($primary-palette, 30) !important;
}

.bg-warn {
  background-color: mat.get-color-from-palette($warn-palette) !important;
}

// 04_TEXT_COLORS
.text-primary {
  color: mat.get-color-from-palette($primary-palette) !important;
}

.text-accent {
  color: mat.get-color-from-palette($accent-palette) !important;
}

.text-warn {
  color: mat.get-color-from-palette($warn-palette) !important;
}

.text-success {
  color: $success_color;
}

.text-grey {
  color: #8c929e !important;
}

.text-accent-light {
  color: mat.get-color-from-palette($accent-palette, 300) !important;
}