.hide-line-overflow {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.scrollable-content {
  overflow: auto;
  @extend %custom-firefox-scrollbar;
}

.vertical-scrollable-content {
  height: 100%;
  max-height: calc(100vh - 370px);
  overflow-y: auto!important;
  @extend %custom-firefox-scrollbar;
}

.horizontal-scrollable-content {
  overflow-x: auto!important;
  @extend %custom-firefox-scrollbar;
}

