@use '@angular/material' as mat;
@include mat.core();

.mat-mdc-tab-nav-bar {
  // default component
  border: none !important;

  .mat-mdc-tab-link-container {
    flex-grow: 0 !important;
    .mat-mdc-tab-list .mat-mdc-tab-links a {
      padding: 0 15px !important;
      color: black !important;
      @include mat.typography-level($typography-config, subtitle-1);
      @extend .text-semibold;
    }
    .mdc-tab--active {
      border-radius: 6px 6px 0 0;
      background: var(--mat-accent) !important;
      .mdc-tab__text-label {
        color: white !important;
        opacity: 1 !important;
      }
    }
  }

  // tabs fill parent width
  &--flex-fill {
    .mat-mdc-tab-links a {
      flex: auto !important;
    }
  }

  // nav tabs with blue bottom-border | transparent background
  &--narrow {
    .mat-mdc-tab-link-container .mat-mdc-tab-list .mat-mdc-tab-links a {
      min-width: 100px !important;
      max-width: 250px !important;
    }
  }

}

 .mat-tab-nav-bar {
  
  // nav tabs with blue bottom-border | transparent background
  &--inverted {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    margin-bottom: 30px;
    .mat-mdc-tab-link-container {
      .mat-mdc-tab-list .mat-mdc-tab-links a {
        color: #666e7e !important;
        @include mat.typography-level($typography-config, body-1);
        @extend .text-regular;
        &.mdc-tab--active {
          opacity: 1 !important;
          border-radius: 6px 6px 0 0;
          color: var(--mat-accent) !important;
          background: transparent !important;
          .mdc-tab__text-label {
            color: var(--mat-accent) !important;
          }
        }
      }
    }
  }
}

.mdc-tab-indicator__content--underline {
  border-radius: var(--mdc-tab-indicator-active-indicator-shape) !important;
  border-top-width: var(--mdc-tab-indicator-active-indicator-height) !important;
  border-color: var(--mdc-tab-indicator-active-indicator-color) !important;
}

 .mdc-tab-indicator__content {
  transition-duration: var(--mat-tab-animation-duration, 250ms) !important;
}