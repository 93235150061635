ul.breadcrumb {
  display: flex;
  list-style: none;
  margin: 0;

  li + li:before {
    padding: 8px;
    color: var(--mat-accent);
    content: '>';
  }

  li {
    display: inline;
    font-size: 14px;

    a {
      color: var(--mat-accent) ;
      text-decoration: none;
      cursor: pointer !important;

      &:hover {
        color: #01447e;
        text-decoration: underline;
      }
    }

    .active {
      color: var(--mat-accent);
      font-weight: bold;
    }
  }
}
