@use '@angular/material' as mat;

// Define the color configuration
@include mat.core();

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$material-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: $typography-config
  )
);

// Create a mixin to generate CSS variables for the palette colors
@mixin define-css-variables($theme) {
  :root {
    --mat-primary: #{mat.get-color-from-palette($primary-palette)};
    --mat-primary-50: #{mat.get-color-from-palette($primary-palette, 50)};
    --mat-primary-100: #{mat.get-color-from-palette($primary-palette, 100)};
    --mat-primary-200: #{mat.get-color-from-palette($primary-palette, 200)};
    --mat-primary-300: #{mat.get-color-from-palette($primary-palette, 300)};
    --mat-primary-400: #{mat.get-color-from-palette($primary-palette, 400)};
    --mat-primary-500: #{mat.get-color-from-palette($primary-palette, 500)};
    --mat-primary-600: #{mat.get-color-from-palette($primary-palette, 600)};
    --mat-primary-700: #{mat.get-color-from-palette($primary-palette, 700)};
    --mat-primary-800: #{mat.get-color-from-palette($primary-palette, 800)};
    --mat-primary-900: #{mat.get-color-from-palette($primary-palette, 900)};
    --mat-primary-A100: #{mat.get-color-from-palette($primary-palette, A100)};
    --mat-primary-A200: #{mat.get-color-from-palette($primary-palette, A200)};
    --mat-primary-A400: #{mat.get-color-from-palette($primary-palette, A400)};
    --mat-primary-A700: #{mat.get-color-from-palette($primary-palette, A700)};

    --mat-accent: #{mat.get-color-from-palette($accent-palette)};
    --mat-accent-30: #{mat.get-color-from-palette($accent-palette, 30)};
    --mat-accent-50: #{mat.get-color-from-palette($accent-palette, 50)};
    --mat-accent-100: #{mat.get-color-from-palette($accent-palette, 100)};
    --mat-accent-200: #{mat.get-color-from-palette($accent-palette, 200)};
    --mat-accent-300: #{mat.get-color-from-palette($accent-palette, 300)};
    --mat-accent-400: #{mat.get-color-from-palette($accent-palette, 400)};
    --mat-accent-500: #{mat.get-color-from-palette($accent-palette, 500)};
    --mat-accent-600: #{mat.get-color-from-palette($accent-palette, 600)};
    --mat-accent-700: #{mat.get-color-from-palette($accent-palette, 700)};
    --mat-accent-800: #{mat.get-color-from-palette($accent-palette, 800)};
    --mat-accent-900: #{mat.get-color-from-palette($accent-palette, 900)};
    --mat-accent-A100: #{mat.get-color-from-palette($accent-palette, A100)};
    --mat-accent-A200: #{mat.get-color-from-palette($accent-palette, A200)};
    --mat-accent-A400: #{mat.get-color-from-palette($accent-palette, A400)};
    --mat-accent-A700: #{mat.get-color-from-palette($accent-palette, A700)};

    --mat-warn: #{mat.get-color-from-palette($warn-palette)};
    --mat-warn-50: #{mat.get-color-from-palette($warn-palette, 50)};
    --mat-warn-100: #{mat.get-color-from-palette($warn-palette, 100)};
    --mat-warn-200: #{mat.get-color-from-palette($warn-palette, 200)};
    --mat-warn-300: #{mat.get-color-from-palette($warn-palette, 300)};
    --mat-warn-400: #{mat.get-color-from-palette($warn-palette, 400)};
    --mat-warn-500: #{mat.get-color-from-palette($warn-palette, 500)};
    --mat-warn-600: #{mat.get-color-from-palette($warn-palette, 600)};
    --mat-warn-700: #{mat.get-color-from-palette($warn-palette, 700)};
    --mat-warn-800: #{mat.get-color-from-palette($warn-palette, 800)};
    --mat-warn-900: #{mat.get-color-from-palette($warn-palette, 900)};
    --mat-warn-A100: #{mat.get-color-from-palette($warn-palette, A100)};
    --mat-warn-A200: #{mat.get-color-from-palette($warn-palette, A200)};
    --mat-warn-A400: #{mat.get-color-from-palette($warn-palette, A400)};
    --mat-warn-A700: #{mat.get-color-from-palette($warn-palette, A700)};
  }
}


// Apply the theme
@include mat.all-component-themes($material-light-theme);
@include mat.all-component-typographies($material-light-theme);

// Apply the CSS variables
@include define-css-variables($material-light-theme);

//Material_icons
.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Outlined', 'Material Icons';
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: transparent !important;
}

.mat-mdc-form-field-infix {
  min-height: 36px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}


