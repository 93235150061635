@use '@angular/material' as mat;
@include mat.core();


// Source : https://codepen.io/mikewheaton/pen/RVbygB
//========== TYPO =============
// 01_TYPO_FONT_FACE
// 02_TYPO_CONFIG
// 03_TYPO_HELPERS
//=============================

// 01_TYPO_FONT_FACE
@font-face {
  font-family: 'Segoe UI Local';
  src: local('Segoe UI');
  font-style: normal;
}

@font-face {
  font-family: 'Lato Regular';
  src: url('~src/assets/fonts/Lato-Regular.eot?') format('eot'),
    url('~src/assets/fonts/Lato-Regular.woff') format('woff'),
    url('~src/assets/fonts/Lato-Regular.ttf') format('truetype'),
    url('~src/assets/fonts/Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

// 02_TYPO_CONFIG
$font-weight-semilight: 200;
$font-weight-regular: 500;
$font-weight-semibold: 600;

$typography-config: mat.define-typography-config(
  $font-family: '"Lato Regular", "Segoe UI", "Segoe UI Local"',
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $headline-6: mat.define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.define-typography-level(16px, 28px, 500),
  $subtitle-2: mat.define-typography-level(14px, 24px, 500),
  $body-1: mat.define-typography-level(16px, 24px, 400),
  $body-2: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $overline: mat.define-typography-level(10px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500)
);

// 03_TYPO_HELPERS
.text-semilight {
  font-weight: $font-weight-semilight !important;
}

.text-regular {
  font-weight: $font-weight-regular !important;
}

.text-semibold {
  font-weight: $font-weight-semibold !important;
}
