@use '@angular/material' as mat;
@include mat.core();

.dashed-btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px;
  cursor: pointer;
  border-style: dashed !important;
  border: 2px solid $accent-border-color;
  border-radius: 6px;

  &__label {
    margin-left: 5px;
    @include mat.typography-level($typography-config, subtitle-1);
    color: var(--mat-accent) ;
  }

  .mat-icon {
    font-size: 30px;
    width: 30px;
  }
}

.dashed-btn.disabled {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px;
  cursor: default;
  border-style: dashed !important;
  border: 2px solid $disabled;
  border-radius: 6px;

  span {
    margin-left: 5px;
    @include mat.typography-level($typography-config, subtitle-1);
    color: $disabled;
  }

  .mat-icon {
    font-size: 30px;
    width: 30px;
    color: $disabled;
  }
}
