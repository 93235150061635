@use '@angular/material' as mat;
@include mat.core();

@import "src/scss/base/colors";
@import "src/scss/base/typography";

.mat-mdc-table {
  &.mat-table--stripped {
    width: 100%;
    border-collapse: separate !important;
    border-spacing: 0 6px !important;

    .mat-mdc-header-row {
      border: none !important;
      height: 50px !important;
      text-align: left;
    }

    .mat-mdc-header-cell {
      color: rgba(0, 0, 0, 0.54) !important;
      @include mat.typography-level($typography-config, caption);
      @extend .text-semibold;
    }

    tbody {
      .mat-mdc-row {
        cursor: pointer;
        text-align: left;
        min-height: 35px;
        height: 35px;
        opacity: 1;
        background: $background-grey;
        border-radius: 6px;
      }

      .mat-mdc-cell {
        height: 100%;
        margin-right: 2%;
      }
    }
  }

  &.mat-table--auto-width {
    .mat-mdc-table {
      width: 100%;
      max-width: 100%;
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell {
      word-wrap: initial;
      border: none !important;
      padding: 0 25px;
      line-break: unset;
      vertical-align: middle;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.mat-table--file {
    width: 100%;
    height: 100%;
    min-height: 37px;

    .mat-mdc-header-row {
      height: 29px !important;

      .mat-mdc-header-cell {
        background: #F4F4F5 0 0 no-repeat padding-box;
        border-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        font: normal normal normal 14px/14px Segoe UI;
        justify-content: flex-start;
        text-align: left;
        white-space: nowrap;

        &:nth-child(1) {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:nth-last-child(1) {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

      }
    }

    tbody {
      .mat-cell {
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
      }

      .icon-background {
        display: flex;
        width: 29px;
        height: 29px;
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        background-color: $table-icon-background;
      }
    }

    .icon {
      padding-left: 14px;
      max-width: 8%;
      vertical-align: middle;
    }

    .action {
      max-width: 12%;
    }
  }

  &.mat-table--header-no-border {
    mat-header-row {
      border: none;
    }
  }

  &.mat-table--card {
    width: 100%;
    border-collapse: separate !important;
    border-spacing: 0 6px !important;

    // dirtyfix Firefox
    thead {
      position: relative;
      height: 56px;
    }

    .mat-mdc-header-cell {
      color: rgba(0, 0, 0, 0.54) !important;
      @include mat.typography-level($typography-config, caption);
      @extend .text-semibold;
    }

    .mat-mdc-header-cell {
      border-width: 0 !important;
    }

    /////////
    .mat-mdc-row{
      .mat-mdc-cell {
        border-top: solid 1px $table-card-row-border !important;
        border-bottom: solid 1px $table-card-row-border !important;

        &:nth-child(1) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-left: solid 1px $table-card-row-border !important;
        }

        &:nth-last-child(1) {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-right: solid 1px $table-card-row-border !important;
        }
      }
    }

    ////////
    tbody {

      .icon-background {
        display: flex;
        width: 29px;
        height: 29px;
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        background-color: $table-icon-background;
      }

      .name {
        color: $table-card-name-color;
        @extend .text-semibold;
      }
    }


    .icon {
      padding-left: 14px;
      max-width: 8%;
    }

    .action {
      max-width: 12%;
    }
  }
}
