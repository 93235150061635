.mat-card {
  box-shadow: none !important;
  border-radius: 0 !important;

  &--overlay-tabs {
    position: relative !important;
    top: -2px !important;
    z-index: 2 !important;
    border-radius: 6px !important;
    padding: 16px !important;
  }

  &--srollable {
    overflow-y: auto !important;
  }

  &--flex {
    @extend %flex-layout-fill;
  }
}
