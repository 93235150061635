.mat-tab-group {
  flex: 1 !important;
  .mat-tab-header {
    height: 45px !important;
  }

  .mat-tab-body-wrapper {
    flex: 1 !important;

    .mat-tab-body-content {
      display: flex;
      flex-direction: column;
    }
  }

  .mat-tab-labels {
    .mat-tab-label {
      &.mdc-tab--active {
        opacity: 1 !important;
        color: var(--mat-accent) !important;
      }
    }
  }

  &.fill-tab-width {
    .mat-tab-labels .mat-tab-label {
      flex: 1 !important;
    }
  }
}
