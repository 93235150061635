.mat-mdc-select {
  /* Default style, apply for every mat-select */
  .mat-mdc-select-arrow {
    color: var(--mat-accent) !important;
  }
}

.mat-select-light {
  border: 1px solid $white;
  padding: 2px 5px;
  border-radius: 4px;

  .mat-mdc-select-value-text {
    color: $white;
  }

  .mat-mdc-select-value {
    display: flex;
    justify-content: center;
  }

  .mat-mdc-select-arrow {
    color: $white;
  }
}

.mat-select-light-panel {
  margin: -30px 6px 0px -6px !important;
  border-radius: 6px !important;
  padding: 0px !important;

  &.ng-animating {
    visibility: hidden;
  }

  .mat-mdc-option {
    padding: 0;
    &.mdc-list-item--selected {
      background: rgba(0, 0, 0, 0.12) !important;
    }

    .mdc-list-item__primary-text {
      color: black;
      margin: auto;
    }

    .mat-pseudo-checkbox-minimal {
      display: none;
    }
  }
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    &.mdc-list-item--selected {
      background: rgba(0, 0, 0, 0.12) !important;
    }

    .mat-pseudo-checkbox {
      display: none;
    }
  }
}

.mat-select-badge {
  color: $white !important;
  padding: 3px 7px 3px 8px;
  .display-value {
    display: flex;
    flex-flow: row;
    align-items: center;

    .display-title {
      max-width: 80%;
      padding-bottom: 1px;
      color: $white;
    }
    .display-chip {
      display: flex;
      width: 20px;
      height: 10px;
      margin-right: 8px;
      border-radius: 10px !important;
      align-items: center;
      align-content: space-evenly;
    }
  }
  .mat-mdc-select-arrow {
    color: $white;
  }
}

.mat-select-badge-panel {
  .mdc-list-item__primary-text {
    display: flex;
    flex-flow: row;
    align-items: center;

    .panel-chip {
      display: flex;
      width: 25px;
      height: 15px;
      margin-right: 20px;
      border-radius: 10px !important;
      align-items: center;
      align-content: space-evenly;
    }

    .panel-title {
      max-width: 80%;
      padding-bottom: 1px;
    }
  }

  .mdc-list-item--selected {
    .panel-title {
      color: black;
    }
  }
}

.mat-select-panel {
  width: 300px !important;
  min-width: calc(100% + 32px) !important;
}

.mat-mdc-option {
  font-size: 14px !important;
}


.cdk-overlay-pane:has(.mat-select-badge-panel) {
  width: 250px !important;
  margin-left: -55px;
}

.cdk-overlay-pane:has(.wide-panel) {
  width: 340px !important;
  margin-left: -75px;

  .wide-panel {
    padding-bottom: 2px !important;
  }
}
