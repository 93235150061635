.mat-progress-bar {
  &.mat-accent {
    // Rounded style
    &.mat-progress-bar--rounded {
      .mat-progress-bar-buffer {
        background-color: lightgrey !important;
      }
      .mat-progress-bar-fill::after {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
    }
  }
}
