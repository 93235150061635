.cdk-overlay-container {
  .cdk-overlay-backdrop--darken {
    background: #000000c2;
  }


}


.mat-dialog-container {
  padding: 0 !important;
  align-self: center;
  overflow: hidden !important;
}



