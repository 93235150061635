mat-label {
  &.required--asterix {
    &:after {
      content: '*';
      padding-left: 4px;
      vertical-align: top;
      color: var(--mat-warn) !important;
    }
  }
}
