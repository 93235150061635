.mat-expansion-panel {
  box-shadow: none !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel-header {
  padding: 0 10px !important;
  border-bottom: solid 0.5px rgba(127, 187, 220, 0.3) !important;
  border-radius: 0 !important;
}

.mat-expansion-panel.lightIndicator{
  .mat-expansion-indicator{
    width: 25px;
    height: 25px;
    border-radius: 5px;

    &:after {
      border-width: 0 3px 3px 0 !important;
      transform: rotate(45deg) !important;
      width: 10px;
      height: 10px;
      color: #5191b5 !important;
    }
  }
}

.mat-expansion-panel:not(.lightIndicator){
  .mat-expansion-indicator {
    width: 25px;
    height: 25px;
    background-color: #0078B9;
    border-radius: 5px;

    &:after {
      border-width: 0 3px 3px 0 !important;
      transform: translateX(7px) rotate(45deg) !important;
      width: 10px;
      height: 10px;
      color: white !important;
    }
  }
}

