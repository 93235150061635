@use '@angular/material' as mat;
@include mat.core();

//========== VARIABLES =============
// 01_BOOTSTRAP_OVERRIDE
// 02_APP_VARIABLES
//=============================

// 01_BOOTSTRAP_OVERRIDE (to avoid conflicts)
//$link-color: mat-color($accent-palette);

//$link-hover-color: currentColor;

$link-hover-decoration: none;
$label-margin-bottom: 0;

// 02_APP_VARIABLES
$app-navbar-height: 40px;
$app-header-height: 69px;

$layout-two-columns-top-padding: 8px;

$tabs-first-level-height: 44px;
$tabs-second-level-height: 48px;

$detail-space-level: 313px;
$detail-space-level-height: 325px;

$expandable-filter-layout_left_col_width: 220px;
