@use '@angular/material' as mat;
@include mat.core();

.select-toggle-card {
  @extend %flex-layout-fill;
  padding: 0 !important;
  color: var(--mat-primary) !important;

  background-color: $card-redaction-eligibility-background !important;
  background-image: url('~src/assets/images/logo/logo.svg') !important;
  background-size: cover !important;
  border-radius: 6px !important;

  &__premium {
    background-color: grey !important;
  }
  
  &__content {
    @extend %flex-layout-fill;
    width: 100%;
    text-align: center;

    form {
      @extend %flex-layout-fill;
    }

    &__img-wrapper {
      display: flex;
      width: 70% !important;
      height: 55px;
      padding-top: 4px !important;
      margin: auto !important;

      img {
        height: 55%;
        margin: auto !important;
      }
    }

    &__title {
      height: 55px;
      margin: 0 auto !important;
      @extend .text-semibold;
      &--centered {
        height: 45px !important;
        @extend %flex-row-center-all;
      }
    }

    &__radio-group {
      display: flex !important;
      align-items: center;
      text-align: left;
      padding: 15px;
      border-top: solid 1px var(--mat-primary) !important;
    }

    &__toggle {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      min-height: 48px !important;
      padding: 5% !important;
      border-top: solid 1px var(--mat-primary) !important;

      mat-label {
        margin-left: 10px !important;
      }

      mat-slide-toggle {
        margin-right: 15px !important;
      }
    }

    &__select {
      display: flex !important;
      align-items: center !important;
      min-height: 48px !important;
      padding: 1% !important;
      color: solid 1px var(--mat-primary) !important;
      border-top: solid 1px var(--mat-primary) !important;
    }
  }
}

.dashboard-card {
  width: 100%;
  flex-direction: column !important;

  padding: 0 !important;
  border: solid 1px #e1edf3;
  border-radius: 6px;

  &__header {
    min-height: 45px;
    @extend .text-semibold;
    @include mat.typography-level($typography-config, subtitle-1);
    @extend %flex-row-center-all;
    text-align: center;
    background-color: #e6f2f8;
    margin-bottom: 2%;
  }

  &__content {
    @extend %flex-layout-fill;
    align-items: flex-start;
  }
}

.dashboard-side-card {
  width: 100%;
  flex-direction: column !important;
  padding: 0 !important;
  border: solid 1px #e1edf3;
  border-radius: 10px;
  horiz-align: center;
  margin-bottom: 5%;

  &__header {
    @extend .text-semibold;
    @include mat.typography-level($typography-config, subtitle-1);
    @extend %flex-row-center-all;
    text-align: center;
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }

  &__content {
    @extend %flex-layout-fill;
    align-items: flex-start;
    margin-top: 0px !important;
  }
}
