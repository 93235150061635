@use '@angular/material' as mat;
@include mat.core();

// Base Style
@import 'scss/base/colors';
@import 'scss/base/typography';
@import 'scss/base/variables';
@import 'scss/base/placeholders';



/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import 'bootstrap/scss/bootstrap';


// Be sure that you only ever include this mixin once!
@include mat.core();

// Material theme
@import 'scss/theme';

// Custom modules themes
@import 'scss/modules/app.modules';


@include mat.all-component-themes($material-light-theme);
@include mat.all-component-typographies($typography-config);

/* Global style */
html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.form-field-density {
  @include mat.form-field-density(-5);
}


::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: $scroll-bar-track;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  border-radius: 10px;
}

.snackbar-container {
  .mdc-snackbar__surface {
    width: 500px;
    height: 48px !important;
    background-color: $background-light-green !important
  }

  width: 500px;

  &.medicalcenterupdated,
  &.success {
    border: 1px solid $light-green !important;
    background-color: $background-light-green !important;
  }
  &.medicalcheckupinprogress,
  &.medicalcenterexisted,
  &.managernotfound,
  &.error {
    border: 1px solid $light-red !important;
    background-color: $background-light-red !important;
  }
}
