.secondary_button {
  width: 219px;
  height: 35px;
  border: 1px solid #0078B9;
  border-radius: 3px;
}

.management_header_button {
  width: 219px;
  height: 35px;
  font: normal normal normal 16px/14px Segoe UI;

  &__primary {
    color: #FFFFFF !important;
    margin-left: 8px !important;

    background: #0078B9 0 0 no-repeat padding-box !important;
    border-radius: 3px;
  }

  &__secondary {
    color: #0078B9 !important;

    border: 1px solid #0078B9 !important;
    border-radius: 3px;
  }
}

.dialog_button {
  width: 18vw;
  height: 4vh;
  margin: 2vh 1vw;
  text-align: center;
  font: normal normal normal 16px/17px Segoe UI;

  &.dialog_primary_button {
    background: #0078B9 0 0 no-repeat padding-box;
    border-radius: 6px;
    border: none;
    color: #FFFFFF;
    position: relative;

    &.disabled {
      background-color: #B2B6BE;
      color: white;
    }

    &.mat-button__spinner {
      @keyframes spinner {
        to {
          transform: rotate(360deg);
        }
      }

      &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid white;
        border-top-color: var(--mat-accent) !important;
        animation: spinner 0.8s linear infinite;
      }
    }
  }

  &.dialog_secondary_button {
    border: 2px solid #0078B9;
    border-radius: 6px;
    background-color: #FFFFFF;
    color: #0078B9;
  }
}

