@import '/src/scss/base/colors';

.mat-mdc-form-field {
  display: inline-block !important;
  position: relative !important;
  text-align: left !important;
  font-weight: 400 !important;
  line-height: 1.125 !important;
  font-family: "Lato Regular", "Segoe UI", "Segoe UI Local" !important;
  letter-spacing: normal !important;

  .mdc-text-field--outlined{
    padding: 0 !important;
  }
  
  .mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
    // height: 40px;
    padding: 0;
  }

  .mat-mdc-text-field-wrapper {
    background-color: $white !important;
    border-radius: 4px !important;
    margin: .25em 0 !important;

    .mat-mdc-form-field-flex {

      display: inline-flex !important;
      align-items: baseline !important;
      box-sizing: border-box !important;
      width: 100% !important;
      padding: 0 .75em 0 .75em !important;
      margin-top: -0.25em !important;
      position: relative !important;
      // display: flex;
      flex-direction: row;
      // align-items: center;

      .mdc-notched-outline {
        border-width: 2px;

        .mdc-notched-outline__leading {
          border-radius: 6px 0 0 6px;
          border-right: none !important;
        }

        .mdc-notched-outline__trailing {
          border-radius: 0 6px 6px 0;
          border-left: none !important;
        }
      }

      .mat-mdc-form-field-infix {
        border-top-width: 0;
        display: flex;
        align-items: center;
        // min-height: 40px;
      }
    }
    
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  &:not(.mat-form-field-disabled) {
    .mat-mdc-text-field-wrapper {
      .mdc-notched-outline {
        border-width: 2px;

        .mdc-notched-outline__leading {
          border-color: var(--mat-accent-500) !important;
        }

        .mdc-notched-outline__trailing {
          border-color: var(--mat-accent-500) !important;
        }
      }

      &:hover {
        .mat-mdc-form-field-flex {
          .mdc-notched-outline {
            .mdc-notched-outline__leading {
              border: 2px solid var(--mat-accent-500) !important;
              border-right: none !important;
            }

            .mdc-notched-outline__trailing {
              border: 2px solid var(--mat-accent-500) !important;
              border-left: none !important;
            }
          }
        }
      }
    }
  }

  &.mat-form-field-disabled {
    .mat-mdc-text-field-wrapper {
      background-color: $disabled;
      color: $disabled !important;
    }

    .mat-mdc-form-field-icon-suffix {
      filter: saturate(0%);
    }

    .mdc-notched-outline__leading {
      border: 1px solid $disabled;
    }

    .mdc-notched-outline__trailing {
      border: 1px solid $disabled;
    }
  }

  &.mat-form-field-invalid {
    .mdc-notched-outline__leading {
      border: 2px solid var(--mat-warn-500) !important;
      border-right: none !important;
    }

    .mdc-notched-outline__trailing {
      border: 2px solid var(--mat-warn-500) !important;
      border-left: none !important;
    }
  }

  .mat-mdc-input-element {
    font-size: 14px !important;
  }
  .mat-mdc-select-placeholder {
    font-size: 14px !important;
  }
}

.cdk-overlay-pane {
  min-width: 180px !important;
}
