.mat-radio-button {
  .mat-radio-label {
    white-space: normal !important;
  }

  .mat-radio-container .mat-radio-outer-circle {
    border-color: var(--mat-accent);
  }

  .mat-radio-disabled .mat-radio-label .mat-radio-container .mat-radio-outer-circle {
    border-color: grey;
  }
}

// Style for radio button with inversed colors

.mat-radio-group {
  &.mat-radio-button-inversed {
    .mat-radio-button .mat-radio-container {
      .mat-radio-outer-circle {
        border-color: white !important;
      }
    }
  }
}
